<template>
  <div class="founder-team-and-funding">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="pb-10 pb-lg-15">
          <h2 class="fw-bolder text-dark">Team And Funding</h2>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label required">Full-Time Founders</label>
          <Field
            @change="
              $emit('activeFounderIdChanged', Number($event.target.value))
            "
            name="activeFounders"
            class="form-select form-select-lg form-select-solid"
            data-control="select2"
            data-placeholder="Select..."
            data-allow-clear="false"
            data-hide-search="true"
            as="select"
            v-model="activeFounderIdLocal"
          >
            <option :value="1">One</option>
            <option :value="2">Two</option>
            <option :value="3">Three</option>
            <option :value="4">Four</option>
            <option :value="5">Five</option>
          </Field>
          <ErrorMessage
            name="activeFounders"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>

        <div className="fv-row mb-10">
          <label className="form-label required">Team Location</label>
          <Field
            @input="$emit('teamLocationChanged', $event.target.value)"
            type="text"
            v-model="teamLocationLocal"
            name="location"
            class="form-control form-control-lg form-control-solid"
            autocomplete="off"
          />
          <ErrorMessage
            name="location"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
export default {
  name: "TeamAndFunding",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    activeFounderId: {
      type: Number,
      required: true,
    },
    teamLocation: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const teamLocationLocal = ref("");
    const activeFounderIdLocal = ref(null);

    watch(
      () => props.activeFounderId,
      (value) => {
        activeFounderIdLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.teamLocation,
      (value) => {
        teamLocationLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    return {
      activeFounderIdLocal,
      teamLocationLocal,
    };
  },
};
</script>

<style scoped></style>
