<template>
  <div class="founder-basic-info">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="pb-10 pb-lg-15">
          <h2 class="fw-bolder text-dark">Basic Info</h2>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label required"
            >Name Of Your Company or Project</label
          >
          <Field
            v-model="founderCompanyNameLocal"
            @input="$emit('founderCompanyNameChanged', $event.target.value)"
            name="companyName"
            class="form-control form-control-lg form-control-solid"
          />
          <ErrorMessage
            name="companyName"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label">Website Link</label>
          <Field
            name="websiteLink"
            v-model="websiteLinkLocal"
            @input="$emit('websiteLinkChanged', $event.target.value)"
            class="form-control form-control-lg form-control-solid"
          />
          <ErrorMessage
            name="websiteLink"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label required">Project Status</label>
          <Field
            name="projectStatusId"
            v-model="projectStatusLocal"
            @input="$emit('projectStatusChanged', Number($event.target.value))"
            class="form-select form-select-lg form-select-solid"
            as="select"
          >
            <option
              v-for="projectStatus in allProjectStatuses"
              :key="projectStatus.id"
              :value="projectStatus.id"
            >
              {{ projectStatus.name }}
            </option>
          </Field>
          <ErrorMessage
            name="projectStatusId"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "BasicInfo",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    founderCompanyName: {
      type: String,
      required: true,
    },
    websiteLink: {
      type: String,
      required: true,
    },
    projectStatus: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const founderCompanyNameLocal = ref("");
    const websiteLinkLocal = ref("");
    const projectStatusLocal = ref("");
    const store = useStore();

    const allProjectStatuses = computed(() => {
      return store.getters["UserModule/getAllProjectStatuses"];
    });

    watch(
      () => props.founderCompanyName,
      (value) => {
        founderCompanyNameLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.websiteLink,
      (value) => {
        websiteLinkLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.projectStatus,
      (value) => {
        projectStatusLocal.value = value;
      },
      {
        immediate: true,
      }
    );
    return {
      founderCompanyNameLocal,
      websiteLinkLocal,
      projectStatusLocal,
      allProjectStatuses,
    };
  },
};
</script>

<style scoped></style>
