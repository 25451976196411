<template>
  <div v-loading="loading" class="founder-signup-info">
    <Form
      @submit="saveFounderData"
      class="form w-100"
      :validation-schema="founderDataValidation"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <BasicInfo
            @founderCompanyNameChanged="founderCompanyNameChanged"
            @websiteLinkChanged="websiteLinkChanged"
            @projectStatusChanged="projectStatusChanged"
            :founder-company-name="founderCompanyName"
            :website-link="websiteLink"
            :project-status="projectStatus"
          />
        </el-col>
        <el-col :span="24">
          <BusinessInfo
            @industriesUpdated="industriesUpdated"
            @customer-groups-updated="customerGroupsUpdated"
            @business-models-updated="businessModelsUpdated"
            :industries-selected="founderIndustryIds"
            :customer-group-selected="customerGroupIds"
            :business-model-selected="businessModelIds"
          />
        </el-col>
        <el-col :span="24">
          <TeamAndFunding
            @team-location-changed="teamLocationChanged"
            @active-founder-Id-changed="activeFounderIdChanged"
            :team-location="teamLocation"
            :active-founder-id="activeFounderId"
          />
        </el-col>
      </el-row>
      <el-row v-if="dataChanged">
        <el-col :span="24">
          <div class="float-end">
            <button
              @click="fetchFounderInfo"
              ref="discardButton"
              type="button"
              class="btn btn-lg btn-secondary"
              style="margin-right: 1rem"
            >
              <span class="indicator-label"> Discard </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <button
              ref="submitButton"
              type="submit"
              class="btn btn-lg btn-primary"
            >
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </el-col>
      </el-row>
    </Form>
  </div>
</template>

<script>
import { getFounderInfo, updateFounderInfo } from "../../api/founder.api";
import { Form } from "vee-validate";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import BasicInfo from "../components/SingUpInfo/BasicInfo";
import BusinessInfo from "../components/SingUpInfo/BusinessInfo";
import TeamAndFunding from "../components/SingUpInfo/TeamAndFunding";
export default {
  name: "FounderSignUpInfo",
  components: { TeamAndFunding, BasicInfo, BusinessInfo, Form },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const founderCompanyName = ref("");
    const websiteLink = ref("");
    const projectStatus = ref(null);
    const founderIndustryIds = ref([]);
    const customerGroupIds = ref([]);
    const businessModelIds = ref([]);
    const activeFounderId = ref([]);
    const teamLocation = ref("");
    const dataChanged = ref(false);
    const submitButton = ref(null);
    const founderDataValidation = Yup.object().shape({
      projectStatusId: Yup.string().required().label("Project Status"),
      industry: Yup.array()
        .required()
        .label("Select Industry Verticals")
        .min(1, "Select minimum 1 value"),
      customerGroup: Yup.array()
        .required()
        .label("Which Customer Groups Do You Focus On?")
        .min(1, "Select minimum 1 value"),
      businessModel: Yup.array()
        .required()
        .label("Primary Business Model")
        .min(1, "Select minimum 1 value"),
      activeFounders: Yup.string().required().label("Full-Time Founders"),
      companyName: Yup.string()
        .required()
        .label("Name Of Your Company or Project"),
      location: Yup.string().required().label("Team Location"),
    });
    const fetchFounderInfo = async () => {
      loading.value = true;
      const {
        data: { data },
      } = await getFounderInfo();
      founderCompanyName.value = data.companyName ? data.companyName : null;
      websiteLink.value = data.websiteLink ? data.websiteLink : null;
      projectStatus.value = data.projectStatus ? data.projectStatus.id : null;
      activeFounderId.value = data.activeFounders ? data.activeFounders : null;
      teamLocation.value = data.teamLocation ? data.teamLocation : null;
      founderIndustryIds.value = data.founderHasIndustry
        ? data.founderHasIndustry.map((object) => object.industry.id)
        : [];
      customerGroupIds.value = data.founderHasCustomerGroup
        ? data.founderHasCustomerGroup.map((object) => object.customerGroup.id)
        : [];
      businessModelIds.value = data.founderHasBusinessModel
        ? data.founderHasBusinessModel.map((object) => object.businessModel.id)
        : [];
      dataChanged.value = false;
      loading.value = false;
    };

    const saveFounderData = async () => {
      loading.value = true;
      try {
        await updateFounderInfo({
          companyName: founderCompanyName.value,
          websiteLink: websiteLink.value,
          projectStatusId: projectStatus.value,
          industry: founderIndustryIds.value,
          customerGroup: customerGroupIds.value,
          businessModel: businessModelIds.value,
          activeFounders: activeFounderId.value,
          location: teamLocation.value,
        });
        await fetchFounderInfo();
        await store.dispatch("UserModule/fetchUserAndSave");
      } catch (e) {
        //
      } finally {
        loading.value = false;
      }
    };

    //Callback from Children

    const industriesUpdated = (data) => {
      founderIndustryIds.value = data;
      dataChanged.value = true;
    };

    const customerGroupsUpdated = (data) => {
      customerGroupIds.value = data;
      dataChanged.value = true;
    };

    const businessModelsUpdated = (data) => {
      businessModelIds.value = data;
      dataChanged.value = true;
    };

    const founderCompanyNameChanged = (data) => {
      founderCompanyName.value = data;
      dataChanged.value = true;
    };

    const websiteLinkChanged = (data) => {
      websiteLink.value = data;
      dataChanged.value = true;
    };

    const projectStatusChanged = (data) => {
      projectStatus.value = data;
      dataChanged.value = true;
    };

    const teamLocationChanged = (data) => {
      teamLocation.value = data;
      dataChanged.value = true;
    };

    const activeFounderIdChanged = (data) => {
      activeFounderId.value = data;
      dataChanged.value = true;
    };

    onMounted(async () => {
      loading.value = true;
      await store.dispatch("UserModule/fetchAllProjectStatuses");
      await fetchFounderInfo();
      loading.value = false;
    });

    // watch(
    //   [expertProjectStatusesIds, expertIndustryIds, expertProfileLink],
    //   () => {
    //     dataChanged.value = true;
    //   }
    // );

    return {
      founderIndustryIds,
      activeFounderId,
      customerGroupIds,
      businessModelIds,
      founderCompanyName,
      websiteLink,
      teamLocation,
      projectStatus,
      dataChanged,
      loading,
      submitButton,
      founderDataValidation,
      //Methods
      fetchFounderInfo,
      industriesUpdated,
      customerGroupsUpdated,
      businessModelsUpdated,
      founderCompanyNameChanged,
      websiteLinkChanged,
      projectStatusChanged,
      teamLocationChanged,
      activeFounderIdChanged,
      saveFounderData,
    };
  },
};
</script>

<style scoped></style>
