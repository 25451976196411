<template>
  <div class="business-info">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="pb-10 pb-lg-15">
          <h2 class="fw-bolder text-dark">Business Info</h2>
        </div>
        <div class="mb-10 fv-row">
          <label class="form-label mb-3 required"
            >Select Industry Verticals</label
          >
          <Field
            class="form-control form-control-lg form-control-solid"
            name="industry"
            v-slot="{ field }"
            v-model="industrySelectedLocal"
          >
            <Multiselect
              @change="$emit('industriesUpdated', $event)"
              v-model="industrySelectedLocal"
              valueProp="id"
              class="multiselect-blue"
              v-bind="field"
              :options="allIndustries"
              mode="tags"
              :searchable="true"
              label="name"
              trackBy="name"
            />
          </Field>
          <ErrorMessage
            name="industry"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>

        <div class="mb-10 fv-row">
          <label class="form-label mb-3 required"
            >Which Customer Groups Do You Focus On?</label
          >
          <Field
            class="form-control form-control-lg form-control-solid"
            name="customerGroup"
            v-slot="{ field }"
            v-model="customerGroupLocal"
          >
            <Multiselect
              @change="$emit('customerGroupsUpdated', $event)"
              v-model="customerGroupLocal"
              valueProp="id"
              class="multiselect-blue"
              v-bind="field"
              :options="customerGroups"
              mode="tags"
              :searchable="true"
              label="name"
              trackBy="name"
            />
          </Field>
          <ErrorMessage
            name="customerGroup"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>

        <div class="mb-10 fv-row">
          <label class="form-label mb-3 required">Primary Business Model</label>
          <Field
            class="form-control form-control-lg form-control-solid"
            name="businessModel"
            v-slot="{ field }"
            v-model="businessModelLocal"
          >
            <Multiselect
              @change="$emit('businessModelsUpdated', $event)"
              v-model="businessModelLocal"
              valueProp="id"
              class="multiselect-blue"
              v-bind="field"
              :options="businessModels"
              mode="tags"
              :searchable="true"
              label="name"
              trackBy="name"
            />
          </Field>
          <ErrorMessage
            name="businessModel"
            class="fv-plugins-message-container invalid-feedback"
          ></ErrorMessage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "BusinessInfo",
  components: {
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    industriesSelected: {
      type: Array,
      required: true,
    },
    customerGroupSelected: {
      type: Array,
      required: true,
    },
    businessModelSelected: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const industrySelectedLocal = ref([]);
    const customerGroupLocal = ref([]);
    const businessModelLocal = ref([]);
    const store = useStore();
    const allIndustries = computed(
      () => store.getters["UserModule/getAllIndustries"]
    );
    const customerGroups = computed(() => {
      return store.getters["FounderModule/getCustomerGroups"];
    });
    const businessModels = computed(() => {
      return store.getters["FounderModule/getBusinessModals"];
    });

    watch(
      () => props.industriesSelected,
      (value) => {
        industrySelectedLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.customerGroupSelected,
      (value) => {
        customerGroupLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.businessModelSelected,
      (value) => {
        businessModelLocal.value = value;
      },
      {
        immediate: true,
      }
    );

    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllIndustries");
      await store.dispatch("FounderModule/fetchCustomerGroups");
      await store.dispatch("FounderModule/fetchBusinessModals");
    });

    return {
      allIndustries,
      customerGroups,
      businessModels,
      industrySelectedLocal,
      customerGroupLocal,
      businessModelLocal,
    };
  },
};
</script>

<style scoped></style>
